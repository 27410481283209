import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { getToday } from "../../ui/utils/DateUtils";
import { useTranslation } from "react-i18next";
import { ModalAnalysis } from "../../modals/Analysis";
import { getAnalyses } from "../../../redux/actions/analysis";

const SPACING = 50;

const visits = ["C1D1", "C1D8", "C1D15", "C2D1", "C2D15", "EOT"];
const destinations = ["CDL", "3rd Part Lab", "Internal Lab"];
const shippingTemperatures = ["Room Temperature", "4°C", "Dry Ice"];

const mockAnalyses = [
  {
    id: 1,
    name: "BMC",
    description: "20 slides per visit",
    visits: ["C1D1", "C1D8"],
    destination: "CDL",
    shippingTemperature: "Room Temperature",
  },
  {
    id: 2,
    name: "PK",
    description: "2 cryovials per timepoint",
    visits: ["C1D8", "C1D15"],
    destination: "3rd Part Lab",
    shippingTemperature: "4°C",
  },
  {
    id: 3,
    name: "MTAP",
    description: "1 FFPE Block per visit",
    visits: ["C2D1", "C2D15"],
    destination: "Internal Lab",
    shippingTemperature: "Dry Ice",
  },
];

export const ScreenAnalysis = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const studyCode = location.state?.studyCode;
  const centerNumber = location.state?.centerNumber;
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const centerUser = user.organization === "Center";
  const studyId = location.state?.studyId;
  const centerId = location.state?.centerId;
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const analyses = mockAnalyses;

  const columns = React.useMemo(() => {
    return [
      { field: "name", headerName: t("name"), width: 200 },
      { field: "description", headerName: t("description"), width: 300 },
      { field: "visits", headerName: t("visits"), width: 200, renderCell: (params) => params.value.join(", ") },
      { field: "destination", headerName: t("destination"), width: 200 },
      { field: "shippingTemperature", headerName: t("shippingTemperature"), width: 200 },
    ];
  }, [t]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "80vh" }}>
          {analyses ? (
            <LocalizedDataGrid
              autoWidth
              slots={{
                noRowsOverlay: NoRowOverlay,
                loadingOverlay: LinearProgress,
              }}
              rows={analyses}
              columns={columns}
              loading={false} 
              hideFooter
              checkboxSelection={!centerUser && !centerId}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[50, 100]}
              rowHeight={30}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CSVDownloader
            data={analyses}
            filename={`${studyCode}${centerNumber ? `_${centerNumber}` : ""}_analyses_${getToday()}`}
            name={t("analysisList")}
          />
        </div>
      </Paper>
      {adminUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalAnalysis
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                console.log("Refresh data");
              }
            }}
          ></ModalAnalysis>
        </>
      )}
    </Grid>
  );
};