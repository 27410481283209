import React from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ScreenSampleConfirmationForm = (props) => {
    const location = useLocation();
  const orderId = location.state?.orderId;
  const centerId = location.state?.centerId;
  const studyId = location.state?.studyId;
  const patientSections = location.state?.patientSections;
  const shipmentData = location.state?.shipmentData;
  const handleConfirmClick = () => {
    console.log("Confirmation clicked");
  };
  console.log(patientSections);
  console.log(shipmentData);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Sample Confirmation
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please confirm the details of your sample submission.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleConfirmClick}>
          Confirm
        </Button>
      </Paper>
    </Grid>
  );
};

export default ScreenSampleConfirmationForm;