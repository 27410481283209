import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ModalStudy } from "../../modals/Study";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import {
  getStudies,
  getStudiesByEmployee,
  getStudiesBySponsorUser,
  resetStudy,
  updateStudy,
} from "../../../redux/actions/study";
import { store } from "../../../store";
import { useNavigate } from "react-router-dom";
import { getCenters } from "../../../redux/actions/center";
import { Select, MenuItem, Button } from "@mui/material";
import { Typography } from "@mui/material";
import CSVDownloader from "../../ui/CSVDownloader";
import { getToday } from "../../ui/utils/DateUtils";
import { showSuccessAlert, showWarningAlert } from "../../ui/utils/AlertUtils";
import { useTranslation } from 'react-i18next';
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";

const SPACING = 50;

export const ScreenStudies = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const sponsorUser = user.organization === "Sponsor";
  const adminUser = user.role === "admin";
  const employeeUser = user.role === "employee" && user.organization === "CDL";
  const studies = useSelector((state) => state.study.studies);
  const isLoading = useSelector((state) => state.study.isLoadingStudies);
  const [changes, setChanges] = React.useState({});
  const [, setSelectedRows] = React.useState([]);
  const [selectedStudiesForDownload, setSelectedStudiesForDownload] =
    React.useState([]);
  const [studiesWithSponsorName, setStudiesWithSponsorName] = React.useState(
    []
  );
  const [visibleStudyCreateEdit, setVisibleStudyCreateEdit] =
    React.useState(false);
  const updated = useSelector((state) => state.study.updatedStudy);

  const saveChanges = async () => {
    const result = await showWarningAlert(t("You're about to update a study"));
    if (result.isConfirmed) {
      for (const id in changes) {
        const newStatus = changes[id];
        await store.dispatch(updateStudy(id, { status: newStatus }));
      }
      setChanges({}); // Clear the changes after they are saved
    }
  };

  const clearChanges = () => {
    setChanges({});
  };

  React.useEffect(() => {
    if (updated) {
      showSuccessAlert(t("The study was successfully updated"));
      store.dispatch(resetStudy());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  React.useEffect(() => {
    // if sponsor , get the studies the sponsor user is associated with
    if (sponsorUser) {
      store.dispatch(getStudiesBySponsorUser(user.id));
    } else if (employeeUser) {
      // if employee, get the studies the employee is associated with
      store.dispatch(getStudiesByEmployee(user.id));
    } else {
      // else, get all studies
      store.dispatch(getStudies());
    }
  }, [sponsorUser, user, employeeUser, updated]);

  React.useEffect(() => {
    if (studies) {
      setStudiesWithSponsorName(
        studies
          .map((study) => ({
            ...study,
            sponsorName: study.sponsor ? study.sponsor.name : "",
          }))
      );
    }
  }, [studies]);

  // on click, navigate to the centers page
  const handleCellClick = (params, event) => {
    if (
      params.field === "cdlStudyCode" ||
      params.field === "sponsorStudyCode"
    ) {
      const study = studies.find((study) => study.id === params.row.id);

      let studyCode = study.cdlStudyCode; // by default, use the cdl study code
      if (sponsorUser) {
        studyCode = study.sponsorStudyCode; // if the user is a sponsor, use the sponsor study code
      }
      navigate(`/centers`, { state: { studyId: params.row.id, studyCode } });
    }
  };

  let columns = [
    {
      field: "sponsorName",
      headerName: t("sponsor"),
      flex: 0.4,
      renderCell: (params) => (
        <Typography variant="body1">{params.value}</Typography>
      ),
    },
    {
      field: "cdlStudyCode",
      headerName: t("cdlStudyCode"),
      flex: 0.2,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => store.dispatch(getCenters(params.row.id))}
        >
          <Typography variant="body1">{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "sponsorStudyCode",
      headerName: t("sponsorStudyCode"),
      flex: 0.3,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Typography variant="body1">{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "status",
      headerName: t("status"),
      flex: 0.2,
      renderCell: (params) =>
        adminUser ? (
          <Select
            fullWidth
            sx={{ minWidth: 120 }}
            value={changes[params.row.id] || params.row.status}
            onChange={(e) => {
              setChanges({
                ...changes,
                [params.row.id]: e.target.value,
              });
            }}
            disabled={
              Object.keys(changes).length > 0 && !changes[params.row.id]
            }
          >
            <MenuItem value="active">{t("active")}</MenuItem>
            <MenuItem value="cancelled">{t("cancelled")}</MenuItem>
            <MenuItem value="completed">{t("completed")}</MenuItem>
            <MenuItem value="planned">{t("planned")}</MenuItem>
          </Select>
        ) : (
          <div>{params.row.status}</div>
        ),
    },
  ];

  if (sponsorUser) {
    columns = [
      {
        field: "sponsorStudyCode",
        headerName: t("sponsorStudyCode"),
        flex: 0.4,
        renderCell: (params) => (
          <div style={{ cursor: "pointer" }}>{params.value}</div>
        ),
      },
      { field: "status", headerName: t("status"), flex: 0.3 },
    ];
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "90vh" }}>
          <LocalizedDataGrid
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            onCellClick={handleCellClick}
            rows={studiesWithSponsorName}
            columns={columns}
            sortModel={sponsorUser ? [{ field: "status", sort: "asc" }] : []}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50]}
            rowHeight={40}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              const selectedStudies = studiesWithSponsorName
                .filter((study) => newSelection.includes(study.id))
                .map((study) => {
                  const filteredStudy = {};
                  columns.forEach((column) => {
                    filteredStudy[column.field] = study[column.field];
                  });
                  return filteredStudy;
                });
              setSelectedRows(newSelection);
              setSelectedStudiesForDownload(selectedStudies);
            }}
          />
        </div>
        {Object.keys(changes).length > 0 && (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "16px 8px", flex: 0.3, margin: "4px " }}
              onClick={clearChanges}
            >
              {t("cancelChanges")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "16px 8px", flex: 0.3, margin: "4px" }}
              onClick={saveChanges}
            >
              {t("updateStatus")}
            </Button>
          </div>
        )}
        {selectedStudiesForDownload.length > 0 && (
          <CSVDownloader
            data={selectedStudiesForDownload}
            filename={`studies_${getToday()}`}
            name={t("selectedStudies")}
          />
        )}
      </Paper>
      {adminUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleStudyCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalStudy
            visible={visibleStudyCreateEdit}
            onClose={async (refresh) => {
              setVisibleStudyCreateEdit(false);
              if (refresh) {
                await store.dispatch(getStudies());
              }
            }}
          ></ModalStudy>
        </>
      )}
    </Grid>
  );
};
