import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Grid, TextField } from "@mui/material";

const CustomDatePicker = ({
  label,
  name,
  value,
  onChange,
  maxDate,
  required,
  error,
  helperText,
}) => {
  return (
    <Grid item xs={4}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          label={label}
          name={name}
          value={dayjs(value)}
          onChange={(newValue) => {
            onChange(newValue.format("YYYY-MM-DD"));
          }}
          format="DD/MM/YYYY"
          maxDate={maxDate}
          sx={{ width: "100%" }}
          required={required}
          slotProps={{
            textField: {
              fullWidth: true,
              error: error,
              helperText: helperText,
            },
          }}
          textField={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default CustomDatePicker;
