import { Grid, Paper, Box, TextField } from "@mui/material";
import { LinearProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import { store } from "../../../store";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { getOrder, updateOrder } from "../../../redux/actions/order";
import OrderTable from "../../ui/OrderTable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import UserInfo from "../../ui/UserInfo";
import "jspdf-autotable";
import {
  getShipmentById,
  updateShipmentComment,
  updateShipmentDelivery,
  updateShipmentStatus,
  updateShipment,
} from "../../../services/shipmentService";
import { updateProductionStatus } from "../../../services/productionService";
import { updateOrderStatus } from "../../../services/orderService";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";
import {
  printCdlTracking,
  printShippingLabel,
  printPackingList,
  printAorForm,
} from "../../ui/PrintFunctions";
import EditShippingAddressDialog from "../../ui/dialogs/EditShippingAddressDialog";
import PackageDetails from "../../ui/PackageDetails";
import ReadOnlyField from "../../ui/ReadOnlyField";
import { useTranslation } from "react-i18next";
import AddressContactInfo from "../../ui/AddressContactInfo";
import EditFieldDialog from "../../ui/dialogs/EditFieldDialog";

export const ScreenShippingInfo = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const order = useSelector((state) => state.order.order);
  const orderId = location.state?.orderId;
  const centerId = location.state?.centerId;
  const [isLoading, setIsLoading] = React.useState(true);
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const shipmentIndex = location.state.shipmentIndex;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [shipment, setShipment] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [packages, setPackages] = React.useState([]);
  const [deliveryDate, setDeliveryDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [open, setOpen] = React.useState(false);
  const [currentField, setCurrentField] = React.useState("");
  const [currentValue, setCurrentValue] = React.useState("");
  const [currentLabel, setCurrentLabel] = React.useState("");
  const study = useSelector((state) =>
    state.study.studies.find((study) => study.cdlStudyCode === studyCode)
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [hospital, setHospital] = React.useState(order?.hospital || "");
  const [ward, setWard] = React.useState(order?.ward || "");
  const [building, setBuilding] = React.useState(order?.building || "");
  const [street, setStreet] = React.useState(order?.street || "");
  const [floor, setFloor] = React.useState(order?.floor || "");
  const [zipcode, setZipcode] = React.useState(order?.zipcode || "");
  const [shippingAddress, setShippingAddress] = React.useState(
    order?.shippingAddress || ""
  );
  const [updateStatus, setUpdateStatus] = React.useState(false);
  const fullAddress = AddressContactInfo({ order });

  const handleDoubleClickAddress = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDoubleClick = (field, label, value) => {
    if (adminUser) {
    setCurrentField(field);
    setCurrentLabel(label);
    setCurrentValue(value);
    setOpen(true);
    }
  };

  const handleSaveAddress = async () => {
    if (
      hospital.trim() === "" ||
      street.trim() === "" ||
      zipcode.trim() === "" ||
      shippingAddress.trim() === ""
    ) {
      setErrorMessage(t("addressError"));
      return;
    }
    const updatedOrder = {
      hospital: hospital.trim() !== "" ? hospital : null,
      ward: ward.trim() !== "" ? ward : null,
      building: building.trim() !== "" ? building : null,
      street,
      floor,
      zipcode,
      shippingAddress,
    };

    store.dispatch(updateOrder(order.id, updatedOrder));
    setUpdateStatus((prevStatus) => !prevStatus);
    setErrorMessage("");
    setDialogOpen(false);
    showSuccessAlert(t("orderAddressUpdated"));
  };

    const handleSave = async (field, newValue) => {
    if (shipment && shipment.id) {
      await updateShipment(shipment.id, field, newValue);
    }
    setUpdateStatus((prevStatus) => !prevStatus);
    showSuccessAlert(t("shipmentUpdated"));
    
    // Refetch the shipment data
    fetchShipment();
  };
  
  const fetchShipment = async () => {
    if (order && order.shipments && order.shipments[shipmentIndex]) {
      const fetchedShipment = await getShipmentById(
        order.shipments[shipmentIndex].id
      );
      setShipment(fetchedShipment);
  
      if (fetchedShipment && fetchedShipment.packageDetails) {
        // Extract the package details
        const packageDetails = fetchedShipment.packageDetails.match(
          /pkg \d+: \d+ l, \d+ h, \d+ w, \d+(\.\d+)? vw/g
        );
        // Convert into an array of objects with length, height, width, and volumetric weight
        const packages = packageDetails?.map((detail) => {
          const parts = detail.split(" ");
          return {
            length: parseInt(parts[2]),
            height: parseInt(parts[4]),
            width: parseInt(parts[6]),
            volumetricWeight: parseFloat(parts[8]),
          };
        });
        setPackages(packages);
      } else {
        setPackages([]);
      }
    } else {
      console.error("Invalid order or shipment index");
    }
  };
  
  React.useEffect(() => {
    fetchShipment();
    // eslint-disable-next-line
  }, [order?.shipments, shipmentIndex, updateStatus]);

  const handleLostShipment = async () => {
    const result = await showWarningAlert(t("markShipmentAsLostWarning"));

    if (result.isConfirmed) {
      try {
        await updateShipmentStatus(
          shipment,
          "lost",
          "Shipment lost - " + new Date().toLocaleDateString()
        );

        // Update productions status to 'lost'
        const updateProductions = shipment.productions?.map((production) =>
          updateProductionStatus(
            production.id,
            "lost",
            null,
            null,
            "Lost as shipment lost on " + new Date().toLocaleDateString()
          )
        );
        await Promise.all(updateProductions);

        await showSuccessAlert(t("shipmentMarkedAsLost"));

        navigate("/order", {
          state: { orderId, order, centerNumber, studyCode },
        });
      } catch (error) {
        console.error(error);
        showErrorAlert(t("errorOccured"));
      }
    }
  };

  const confirmDelivery = async () => {
    const result = await showWarningAlert(t("confirmDeliveryWarning"));

    if (result.isConfirmed) {
      try {
        await updateShipmentDelivery(shipment.id, "delivered", deliveryDate);

        // Update productions status to 'at center'
        const updateProductions = shipment.productions?.map((production) =>
          updateProductionStatus(production.id, "at center")
        );
        await Promise.all(updateProductions);

        // Update the order status
        let orderStatus = "delivered 1/2";
        const totalShippedQuantity = order.shipments.reduce(
          (sum, shipment) => sum + shipment.shippedQuantity,
          0
        );

        if (totalShippedQuantity === order.totalQuantity) {
          orderStatus = "delivered";
        }

        await updateOrderStatus(order.id, orderStatus);
        showSuccessAlert("The shipment has been confirmed as delivered");

        navigate("/order", {
          state: {
            orderId,
            order,
            centerNumber,
            studyCode,
            studyId: study.id,
            centerId,
          },
        });
      } catch (error) {
        console.error(error);
        showErrorAlert(t("errorOccured"));
      }
    }
  };

  const handleAOR = () => {
    navigate("/aor-form", {
      state: {
        orderId,
        order,
        centerNumber,
        studyCode,
        shipmentIndex,
        centerId,
        studyId: study.id,
      },
    });
  };

  const seeAOR = () => {
    navigate("/aor", {
      state: {
        orderId,
        order,
        centerNumber,
        studyCode,
        shipmentIndex,
        centerId,
        studyId: study.id,
      },
    });
  };

  const materialsTypeMaterial = order?.Supplies?.filter(
    (supply) => supply.Material.type === "material"
  );
  const materialsTypeSupply = order?.Supplies?.filter(
    (supply) => supply.Material.type === "supply"
  );
  const materialsTypeReagent = order?.Supplies?.filter(
    (supply) => supply.Material.type === "reagent"
  );
  const materialsTypeDocuments = order?.Supplies?.filter(
    (supply) => supply.Material.type === "document"
  );

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "shippingQuantity",
        headerName: t("sentQuantity"),
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const productionsWithSameMaterial =
            shipment?.productions?.filter(
              (production) => production.materialId === params.row.Material.id
            ) || [];

          return <div>{productionsWithSameMaterial.length}</div>;
        },
      },
      {
        field: "productionsSent",
        headerName: t("selectedProductions"),
        flex: 0.3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => {
          const productionsWithSameMaterial =
            shipment?.productions.filter(
              (production) => production.materialId === params.row.Material.id
            ) || [];
          const references = productionsWithSameMaterial?.map((production) => {
            if (params.row.Material.type !== "material") {
              return `(${production.batchNumber}) ${production.reference} ${production.comment}`;
            } else {
              return `(${production.batchNumber}) ${production.reference}`;
            }
          });
          return references.join(", ");
        },
        renderCell: (params) => (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {params.value}
          </div>
        ),
      },
    ];

    if (!rows || rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        rowHeight={40}
        hideFooter
        autoHeight
      />
    );
  };

  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  React.useEffect(() => {
    if (shipment) {
      setComment(shipment.comment);
    }
  }, [shipment]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <OrderTable
            order={order}
            studyCode={studyCode}
            centerNumber={centerNumber}
          />

          <Grid container justifyContent="space-around">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                printCdlTracking(order, shipment, study, centerNumber);
              }}
              style={{ padding: "16px 32px", margin: "16px" }}
            >
              {t("cdlTracking")}
            </Button>
            {["shipped", "programmed"].includes(shipment?.status) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    printAorForm(order, shipment, centerNumber, study);
                  }}
                  style={{ padding: "16px 32px", margin: "16px" }}
                >
                  {t("aorForm")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    printPackingList(shipment, study, order, centerNumber);
                  }}
                  style={{ padding: "16px 32px", margin: "16px" }}
                >
                  {t("packingList")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    printShippingLabel(order, shipment);
                  }}
                  style={{ padding: "16px 32px", margin: "16px" }}
                >
                  {t("shippingLabel")}
                </Button>
              </>
            )}
          </Grid>
          <ReadOnlyTextField
            title={t("shippingAddress").toUpperCase()}
            value={fullAddress}
            multiline
            maxRows={4}
            onDoubleClick={adminUser ? handleDoubleClickAddress : null}
          />
          <EditShippingAddressDialog
            open={dialogOpen}
            handleClose={handleClose}
            hospital={hospital}
            setHospital={setHospital}
            ward={ward}
            setWard={setWard}
            building={building}
            setBuilding={setBuilding}
            street={street}
            setStreet={setStreet}
            floor={floor}
            setFloor={setFloor}
            zipcode={zipcode}
            setZipcode={setZipcode}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            errorMessage={errorMessage}
            handleSave={handleSaveAddress}
          />
          <Box sx={{ p: 2 }}>
            <div style={{ width: "100%" }}>
              <TextField
                value={t("materialOrder").toUpperCase()}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                fullWidth
              />{" "}
              <div id="packing-list">
                <CustomDataGrid
                  rows={materialsTypeMaterial}
                  headerName={t("material")}
                />
                <CustomDataGrid
                  rows={materialsTypeSupply}
                  headerName={t("supply")}
                />
                <CustomDataGrid
                  rows={materialsTypeReagent}
                  headerName={t("reagent")}
                />
                <CustomDataGrid
                  rows={materialsTypeDocuments}
                  headerName={t("document")}
                />{" "}
              </div>
            </div>
          </Box>
          <Box sx={{ p: 2 }}>
            <TextField
              value={t("shippingInformation").toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <Grid container spacing={2} style={{ marginTop: "4px" }}>
              <ReadOnlyField
                label={t("preparationDate")}
                type="date"
                name="preparationDate"
                value={shipment?.preparationDate?.split("T")[0] || ""}
                onDoubleClick={() =>
                  handleDoubleClick(
                    "preparationDate",
                    t("preparationDate"),
                    shipment?.preparationDate?.split("T")[0] || ""
                  )
                }
              />
              <ReadOnlyField
                label={t("sendingDate")}
                type="date"
                name="sendingDate"
                value={shipment?.sendingDate?.split("T")[0] || ""}
                onDoubleClick={() =>
                  handleDoubleClick(
                    "sendingDate",
                    t("sendingDate"),
                    shipment?.sendingDate?.split("T")[0] || ""
                  )
                }
              />
              <ReadOnlyField
                label={t("carrierOrderDate")}
                type="date"
                name="carrierOrderDate"
                value={shipment?.carrierOrderDate?.split("T")[0] || ""}
                onDoubleClick={() =>
                  handleDoubleClick(
                    "carrierOrderDate",
                    t("carrierOrderDate"),
                    shipment?.carrierOrderDate?.split("T")[0] || ""
                  )
                }
              />
              <ReadOnlyField
                label={t("carrier")}
                name="carrier"
                value={shipment?.carrier || ""}
                onDoubleClick={() =>
                  handleDoubleClick(
                    "carrier",
                    t("carrier"),
                    shipment?.carrier || ""
                  )
                }
              />
              <ReadOnlyField
                label={t("airwaybill")}
                name="packingSlip"
                value={shipment?.packingSlip || ""}
                onDoubleClick={() =>
                  handleDoubleClick(
                    "packingSlip",
                    t("airwaybill"),
                    shipment?.packingSlip || ""
                  )
                }
              />
              <ReadOnlyField
                label={t("numberOfPackages")}
                type="number"
                name="numPackages"
                value={packages?.length || ""}
              />
            </Grid>
            <Grid
              className="shipping-information"
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "8px" }}
            >
              {Array.from({ length: packages?.length }, (_, index) => (
                <PackageDetails
                  key={index}
                  index={index}
                  packageData={packages[index]}
                />
              ))}
            </Grid>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                id="comment"
                name="comment"
                label={t("comment")}
                multiline
                rows={4}
                sx={{ m: 1, width: "70%" }}
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: "fit-content",
                  width: "20%",
                  m: 1,
                  padding: "16px",
                }}
                onClick={() => {
                  updateShipmentComment(shipment.id, comment);
                  showSuccessAlert("The comment was successfully updated");
                }}
              >
                {t("updateComment")}
              </Button>
            </Box>
            <UserInfo
              title={t("personInChargeOfShipping").toUpperCase()}
              user={shipment?.shippedBy}
              date={dayjs(shipment?.sendingDate).format("DD/MM/YYYY")}
            />
            {shipment?.receivedBy && (
              <UserInfo
                title={t("personInChargeOfReception").toUpperCase()}
                user={shipment?.receivedBy}
                date={dayjs(shipment?.confirmedDeliveryDate).format(
                  "DD/MM/YYYY"
                )}
              />
            )}
            <Grid container spacing={2} style={{ marginTop: "8px" }}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    navigate("/order", {
                      state: {
                        orderId,
                        order,
                        centerNumber,
                        studyCode,
                        studyId: study.id,
                        centerId,
                      },
                    });
                  }}
                  style={{
                    padding: "16px",
                    marginBottom: "4px",
                    width: "350px",
                  }}
                >
                  {t("backToOrder")}
                </Button>
              </Grid>
              {shipment?.status === "shipped" && (
                <>
                  <Grid item xs={4} container justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLostShipment}
                      style={{
                        padding: "16px",
                        marginBottom: "4px",
                        width: "350px",
                      }}
                    >
                      {t("shipmentLost")}
                    </Button>
                  </Grid>
                  <Grid item xs={4} container justifyContent="right">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        label={t("deliveryDate")}
                        type="date"
                        name="deliveryDate"
                        value={dayjs(deliveryDate)}
                        onChange={setDeliveryDate}
                        format="DD/MM/YYYY"
                        maxDate={dayjs()} // restricts user from selecting a date after today
                        sx={{ width: "350px" }} // max width to prevent DatePicker from becoming too wide
                        variant="filled"
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} container justifyContent="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={confirmDelivery}
                      style={{
                        padding: "16px",
                        marginBottom: "4px",
                        width: "350px",
                      }}
                    >
                      {t("confirmDelivery")}
                    </Button>
                  </Grid>
                </>
              )}
              {shipment?.deliveryDate && (
                <Grid item xs={4}>
                  <TextField
                    label={t("deliveryDate")}
                    fullWidth
                    name="deliveryDate"
                    value={dayjs(shipment?.deliveryDate).format("DD/MM/YYYY")}
                    disabled
                  />
                </Grid>
              )}
              {shipment?.status === "delivered" && (
                <Grid item xs={4} container justifyContent="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAOR}
                    style={{
                      padding: "16px",
                      marginBottom: "4px",
                      width: "350px",
                    }}
                  >
                    {t("completeAor")}
                  </Button>
                </Grid>
              )}
              {shipment?.status === "received" && (
                <Grid item container justifyContent="right" xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={seeAOR}
                    style={{
                      padding: "16px",
                      marginBottom: "4px",
                      width: "350px",
                    }}
                  >
                    {t("seeAor")}
                  </Button>
                </Grid>
              )}
              <EditFieldDialog
                open={open}
                onClose={() => setOpen(false)}
                field={currentField}
                label={currentLabel}
                value={currentValue}
                onSave={handleSave}
              />
            </Grid>
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};
